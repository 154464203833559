import $ from 'jquery';
import Swiper from 'swiper';
import Cookies from 'js-cookie'
import { Navigation, Pagination, Autoplay, EffectFade} from 'swiper/modules';
import 'core-js';

window.Swiper = Swiper;
window.$ = $;
window.Cookies = Cookies;


$(function () {
  if (Cookies.get('consent') === undefined) {
    $('#agreeCookie').find('.agree-cookie-btn').on('click', function () {
      $('#agreeCookie').addClass('approved');
      Cookies.set('consent', 'true');
    });
  } else {
    $('#agreeCookie').remove();
  }
  //scroll top
  $('#back_to_top').click(function (event) {
    $("html,body").animate({ scrollTop: 0 }, 1000);
  });

  // scroll header
  function headerScroll() {
    if ($(window).scrollTop() > 100) {
      $('.header').addClass("min-header");
      $('#back_to_top').addClass("is-active");
    } else {
      $('.header').removeClass("min-header");
      $('#back_to_top').removeClass("is-active");
    }
  }
  headerScroll();
  $(window).on('scroll', function () {
    headerScroll();
  });

  $('.header-mobile-menu').on('click', function () {
    $('.header-menu-outer').addClass('is-active');
  });

  $('.header-menu-close, .header-menu-mask').on('click', function () {
    $('.header-menu-outer').addClass('is-hide');
    setTimeout(() => {
      $('.header-menu-outer').removeClass('is-active is-hide');
    }, 300);
  });

  $('.menu-link').click(function (e) {
    if (window.innerWidth <= 1024) {
      e.preventdefault;
      $(this).parent('.header-menu-link').toggleClass('is-active');
    }else {
      $(this).parent('.header-menu-link').toggleClass('is-active');
    }
  });

  // dropdown
  $('.dropdown-btn').on('click', function () {
    const id = $(this).attr('id');
    const dropdown_is_active = $('[aria-labelledby=' + id + ']').hasClass("is-active");
    if (dropdown_is_active >= 1) {
      $(this).removeClass('is-active');
      $('[aria-labelledby=' + id + ']').removeClass('is-active');
      if (window.innerWidth <= 640) {
        $('body').css({
          'height': '',
          'overflow': '',
        })
      }
    } else {
      $(this).addClass('is-active');
      $(".dropdown-list:not([aria-labelledby=" + id + "]").removeClass('is-active');
      $('[aria-labelledby=' + id + ']').addClass('is-active');
      if (window.innerWidth <= 640) {
        $('body').css({
          'height': '100vh',
          'overflow': 'hidden',
        })
      }
    }
  });
  $(document).click(function (event) {
    if (!$(event.target).closest('.dropdown-btn').length) {
      if (!$(event.target).closest('.group-opts').length) {
        if ($('.dropdown-list').is(":visible")) {
          $('.dropdown-btn').removeClass('is-active');
          $(".dropdown-list").removeClass('is-active');
          if (window.innerWidth <= 640) {
            $('body').css({
              'height': '',
              'overflow': '',
            })
          }
        }
      }
    }
  });
  var home_banner = new Swiper('#home_banner', {
    modules: [Navigation, Autoplay, EffectFade],
    loop: true,
    autoplay: {
      speed: '5000',
    },
    effect: "fade",
    speed: 1000,
  });
  var contact_banner = new Swiper('#contact_banner', {
    modules: [ Autoplay, EffectFade],
    loop: true,
    autoplay: {
      speed: '3000',
    },
    effect: "fade",
    speed: 1000,
  });
  var histories_timeline = new Swiper('#histories_timeline', {
    modules: [Navigation],
    slidesPerView: 1.5,
    slidesOffsetBefore: 25,
    navigation: {
      nextEl: '.swiper-btn-next',
      prevEl: '.swiper-btn-prev',
    },
    breakpoints: {
      680: {
        slidesPerView: 2.5,
        slidesOffsetBefore: 0,
      },
      1024: {
        slidesPerView: 3.5,
        slidesOffsetBefore: 0,
      },
      1300: {
        slidesPerView: 4.5,
        slidesOffsetBefore: 0,
      }
    }
  });

});
